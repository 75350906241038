<template>
  <div class="main lodgements">
    <div class="container d-flex">
      <b-row class="h-100 align-items-center mx-auto my-auto">
        <b-col class="col-12 lodgement-container">
          <div class="d-flex">
            <div id="clock" class="pl-4 align-self-center">
              <timer class="time" :time="prettyTime" :warning="runningOutWarning"></timer>
            </div>
            <div class="align-self-center ml-auto d-flex">
              <div
                class="work-allocation align-self-center d-inline-block"
                v-tooltip="{
                  content:
                    workAllocationCount > 0
                      ? `${workAllocationCount} ${$t('lodgements.allocations-hover')}`
                      : `${$t('lodgements.allocations-hover-no-record')}`,
                  placement: 'bottom',
                  classes: ['light'],
                  offset: 10
                }"
              >
                <div
                  v-if="workAllocationCount > 0"
                  :key="`work-allocation-count-${reloadedWorkAllocations}`"
                  class="numberCircle animate__animated animate__bounceIn"
                >
                  <p class="mb-0 mx-auto">
                    {{ workAllocationCount }}
                  </p>
                </div>
                <router-link
                  v-if="workAllocationCount > 0"
                  :to="{
                    path: `/work/${currentSimulation.code}`
                  }"
                  target="_blank"
                >
                  <i class="fal fa-layer-group mr-2"></i>
                </router-link>
                <i v-else class="fal fa-layer-group mr-2 disabled"></i>
              </div>
              <div class="align-self-center d-inline-block mx-3">
                <img
                  src="/assets/img/svg/claims/policy.svg"
                  alt="policy"
                  @click="tipsMenuOver = !tipsMenuOver"
                  v-tooltip="{
                    content: `${$t('lodgements.policies-hover')}`,
                    placement: 'bottom',
                    classes: ['light'],
                    offset: 10
                  }"
                  class="align-self-center"
                />
              </div>
            </div>
          </div>
          <b-card no-body>
            <b-overlay :show="isCountingDown" rounded="sm" variant="dark" opacity="0.3" blur="">
              <b-card-body>
                <claim-form
                  :key="`claim-form-${reloadedClaimForm}`"
                  :startedAt="startedAt"
                  :disabled="disabledGame"
                  :status="gameStatus"
                  :loadingForm="loading"
                  :title="$t('lodgements.title')"
                ></claim-form>
              </b-card-body>
              <template #overlay>
                <div
                  :key="`game-start-countdown-${gameStartTimerCount}`"
                  class="count-down animate__animated animate__bounceIn"
                >
                  {{ gameStartTimerCount }}
                </div>
              </template>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <policy-tip-page :menuOver="tipsMenuOver" @toggleClosed="tipsMenuOver = false" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { formatPayment, gameDuration } from '@/constants/config'
import { pageVisibilityMixin } from '@/mixins/pageVisibilityMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import { validationMixin } from 'vuelidate'
import ClaimForm from '@/components/Form/ClaimForm.vue'
import moment from 'moment'
import PolicyTipPage from '@/components/Pages/PolicyTipPage'
import Timer from '@/components/Common/Timer'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  components: {
    ClaimForm,
    'policy-tip-page': PolicyTipPage,
    Timer,
    'v-select': vSelect
  },
  data() {
    return {
      startedAt: null,
      startTime: false,
      loading: true,
      isRunning: false,
      minutes: 0,
      seconds: 0,
      time: 0,
      timer: null,
      gameStartTimer: null,
      gameStartTimerCount: 4,
      isCountingDown: false,
      currentGame: null,
      currentTeamWeek: null,
      currentWorkAllocations: null,
      disabledGame: true,
      localTimestamp: 0,
      runningOutWarning: false,
      gameStatus: 0,
      gameDuration,
      tipsMenuOver: false,
      reloadedWorkAllocations: 0,
      reloadedClaimForm: 0
    }
  },
  mixins: [pageVisibilityMixin, pusherMixin, validationMixin],
  methods: {
    ...mapActions([
      'completeWorkAllocation',
      'fetchGame',
      'fetchOpenTeamWeek',
      'fetchWorkAllocations',
      'resetTempClaim',
      'updateApprovalCode'
    ]),
    async detectGameChange(game) {
      // get local unix timestamp
      this.localTimestamp = moment().unix()
      let timestampGap = this.localTimestamp - game.time
      let gameDuration = await this.gameDuration
      if (game.status === 0) {
        this.disabledGame = true
        this.gameStatus = 0
      } else if (game.status === 1) {
        // check if time gap is in game duration range and give 3s grace period
        if (timestampGap <= gameDuration && timestampGap > -3) {
          this.disabledGame = false
          // restart the timer based on remaining time
          await this.startTimer(gameDuration - timestampGap)
        } else {
          // close the game if time gap isn't in range
          this.disabledGame = true
          this.gameStatus = 2
        }
      } else {
        this.disabledGame = true
        this.gameStatus = 2
        await this.resetTimer()
      }
    },
    async getGame() {
      if (!this.currentTeamWeek) return

      await this.fetchGame(this.currentTeamWeek.open_day_id)
      this.currentGame = this.game
    },
    async getLodgementData(id) {
      this.loading = true
      await this.resetTimer()
      await this.getOpenTeamWeek(id)
      await this.getWorkAlloctions()
      await this.getGame()
      await this.initiateListener()
      this.loading = false
    },
    async getOpenTeamWeek(id) {
      const currentSimulationTeamId = this.currentUser.simulations.find(
        (simulation) => simulation.id === id
      )

      if (currentSimulationTeamId) {
        await this.fetchOpenTeamWeek({
          simulationId: this.currentSimulation.id,
          teamId: currentSimulationTeamId.simulationTeamId
        })
        this.currentTeamWeek = this.openTeamWeek
      }
    },
    async getWorkAlloctions() {
      if (!this.currentTeamWeek) return

      await this.fetchWorkAllocations(this.currentTeamWeek.open_week_id)
      this.currentWorkAllocations = this.workAllocations
    },
    async initiateDayControlListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`day-control.${this.currentTeamWeek.team_id}`)
      await channel.bind(
        'day-control',
        () => {
          this.getLodgementData(this.currentSimulation.id)
        },
        channel.unbind()
      )
    },
    async initiateGameControlListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-control.${this.currentTeamWeek.team_id}`)
      await channel.bind(
        'game-control',
        (data) => {
          if (data.status === 1) {
            // start game kickoff countdown
            this.startGameStartTimer(data)
          } else {
            this.currentGame = data
            this.resetGameStartTimer()
          }
        },
        channel.unbind()
      )
    },
    async initiateGameSettingsListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-settings.${this.currentTeamWeek.team_id}`)
      await channel.bind(
        'game-settings',
        (data) => {
          const approvalCodeStatus = parseInt(data.approval_code)
          this.updateApprovalCode(approvalCodeStatus)
        },
        channel.unbind()
      )
    },
    async initiateWeekControlListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`week-control.${this.currentTeamWeek.team_id}`)
      await channel.bind(
        'week-control',
        () => {
          this.getLodgementData(this.currentSimulation.id)
          this.resetTempClaim()
          this.reloadedClaimForm += 1
        },
        channel.unbind()
      )
    },
    async initiateWorkAllocationListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(
        `work-allocation.${this.currentUser.id}.${this.currentSimulation.id}`
      )
      await channel.bind(
        'work-allocation',
        (data) => {
          if (data.allocation) {
            this.getWorkAlloctions()
          }

          // check if claim is completed and update record
          if (data.claim) {
            this.completeWorkAllocation(data.claim)
          }
        },
        channel.unbind()
      )
    },
    async initiateListener() {
      await Promise.all([
        this.initiateDayControlListener(),
        this.initiateGameControlListener(),
        this.initiateGameSettingsListener(),
        this.initiateWeekControlListener(),
        this.initiateWorkAllocationListener()
      ])
    },
    reset() {
      this.resetTimer()
      this.runningOutWarning = false
      this.gameStatus = 0
    },
    async resetGameStartTimer() {
      this.isCountingDown = false
      clearInterval(this.gameStartTimer)
      this.gameStartTimerCount = 4
    },
    async resetTimer() {
      this.isRunning = false
      clearInterval(this.timer)
      this.timer = null
      this.time = 0
      this.seconds = 0
      this.minutes = 0
    },
    async startGameStartTimer(data) {
      await this.resetGameStartTimer()
      this.gameStartTimer = setInterval(() => {
        this.isCountingDown = true
        if (this.gameStartTimerCount > 1) {
          this.gameStartTimerCount--
        } else {
          clearInterval(this.gameStartTimer)
          this.currentGame = data
          this.isCountingDown = false
        }
      }, 1000)
    },
    startTimer(timestampGap) {
      this.startTime = true
      this.startedAt = new Date()

      this.time = timestampGap
      this.isRunning = true
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            clearInterval(this.timer)
            this.reset()
            this.disabledGame = true
            this.gameStatus = 2
          }
        }, 1000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'claims',
      'currentSimulation',
      'currentUser',
      'game',
      'getCurrentWorkAllocation',
      'openTeamWeek',
      'workAllocations'
    ]),
    prettyTime() {
      if (this.time <= 10 && this.time > 0) {
        this.runningOutWarning = true
      } else {
        this.runningOutWarning = false
      }
      let time = this.time / 60
      let minutes = parseInt(time)
      let seconds = Math.round((time - minutes) * 60)
      return `${minutes}:${seconds}`
    },
    workAllocationCount() {
      if (_.has(this.currentWorkAllocations, 'claimMasters')) {
        const unsubmittedClaims = this.currentWorkAllocations.claimMasters.filter(
          (claim) => !claim.completed
        )
        return unsubmittedClaims.length
      } else {
        return 0
      }
    }
  },
  filters: {
    formatPayment(value) {
      return formatPayment(value)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.gameStartTimer)
    this.timer = null
    this.gameStartTimer = null
    this.leaveChannels()
  },
  watch: {
    currentGame: {
      handler(game) {
        this.detectGameChange(game)
      },
      deep: true
    },
    currentSimulation: {
      async handler(newVal) {
        if (newVal) {
          await this.getLodgementData(newVal.id)
        }
      },
      immediate: true
    },
    workAllocationCount() {
      this.reloadedWorkAllocations += 1
    },
    async pageVisibilityTimeGap(newVal) {
      // wait for 3s to reload game status if page is idle
      if (newVal > 3000) {
        await this.getGame()
      }
    }
  }
}
</script>
